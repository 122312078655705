(($) => {

    /**
     * Gulp include scripts
     *
     * @link https://www.npmjs.com/package/gulp-include
     */
    //=require ../../template-parts/blocks/example/block-example.js

    /**
     * jQuery Document Ready
     *
     * @link https://learn.jquery.com/using-jquery-core/document-ready/
     */
    $(() => {
        var w = $(window).width();
		if (w > 1199) {
			$(window).on('scroll', function() {
				if ($(window).scrollTop() > 500) {
					$('header').addClass('sticky');
				} 
			});
			$(window).on('scroll', function() {
				if ($(window).scrollTop() < 500) {
					$('header').removeClass('sticky');
				}
			});
		}

        $('.hamburger').click(function(){
			let status = $(this).attr('aria-expanded');
			if (status == 'true') {
				$(this).attr('aria-expanded', false);
				$(this).siblings('.mobile-menu').removeClass('active');
			} else {
				$(this).attr('aria-expanded', true);
				$(this).siblings('.mobile-menu').addClass('active');
			}
		});

		$('.js-mobile-sublinks').click(function(){
			let parent =  $(this).closest('li');
			if (parent.hasClass('active')) {
				parent.removeClass('active');
			} else {
				parent.siblings().removeClass('active');
				parent.addClass('active');
			}
		});

        $('.js-sublinks').hover(
			function() {
				$(this).addClass('active');
			}, function() {
				$(this).removeClass('active');
			}
		);

		$('.js-accordion-toggle').click(function(){
			let item = $(this).closest('li');
			if (item.hasClass('active')) {
				item.removeClass('active');
			} else {
                item.siblings().removeClass('active');
				item.addClass('active');
			}
		});

        if ($('.logo-slider').length) {
            $('.logo-slider').slick({
                arrows: false,
                infinite: true,
                slidesToShow: 8,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 0,
                speed: 8000,
                pauseOnHover: false,
                cssEase: 'linear',
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        }

        function animateNumber(element, target, afterNumber, duration) {
            const start = 0;
            const increment = target / (duration / 16);
            let currentValue = start;

            function updateCounter() {
                currentValue += increment;
                if (currentValue >= target) {
                    element.textContent = target + afterNumber;
                } else {
                    element.textContent = Math.floor(currentValue) + afterNumber;
                    requestAnimationFrame(updateCounter);
                }
            }
            updateCounter();
        }

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const element = entry.target;
                    const target = parseInt(element.getAttribute('data-target'), 10);
                    const afterNumber = element.getAttribute('data-target').replace(/[0-9]/g, '');
                    animateNumber(element, target, afterNumber, 2000);
                    observer.unobserve(element);
                }
            });
        }, { threshold: 0.5 });

        document.querySelectorAll('.counter').forEach(counter => {
            observer.observe(counter);
        });
    });

    jQuery(document).on('gform_post_render', function(event, form_id, current_page){
        // Inline label forms: Add active class if input has value
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number], .js-gform textarea').each(function () {
            if ($(this).val().length) {
                $(this).closest('.gfield').addClass('active');
            }
        });

        $('.js-gform select').on('change', function() {
            if ($(this).val() === "") {
                $(this).closest('.gfield').removeClass('active');
            } else {
                $(this).closest('.gfield').addClass('active');
            }
        });

        // Inline label forms: Add active class on focus
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number], .js-gform textarea').on('focus', function () {
            $(this).closest('.gfield').addClass('active');
        });

        // Inline label forms: Remove active class on blur
        $('.js-gform input[type=text], .js-gform input[type=tel], .js-gform input[type=email], .js-gform input[type=number], .js-gform textarea').on('blur', function () {
            if (!$(this).val().length || $(this).val().includes('_')) {
                $(this).closest('.gfield').removeClass('active');
            }
        });
    });

})(jQuery);
